.text-center{
  text-align: center;
}
.list-reset{
  list-style: none;
  margin:0;
  padding:0;
}
.no-margin{
  margin:0;
}
.make-bold{
  font-weight: 700;
}
