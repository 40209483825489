@font-face {
  font-family: 'URW Grotesk';
  font-style: normal;
  font-weight: 400; /* or 400 */
  src: url('/fonts/244BAF_0_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/244BAF_0_0.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/244BAF_0_0.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'URW Grotesk';
  font-style: normal;
  font-weight: 700; /* or 400 */
  src: url('/fonts/244BAF_1_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/244BAF_1_0.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/244BAF_1_0.ttf')  format('truetype'); /* Safari, Android, iOS */
}

html {
  font-family:'URW Grotesk';
  font-weight: 400;
  font-size: $html-size-sm;
  @media screen and (min-width: $breakpoint-laptop-min){
    font-size: $html-size;
  }
}
p {
  font-size: $paragraph-size-sm;
  line-height: $line-height-sm;
  @media screen and (min-width: $breakpoint-laptop-min){
    font-size: $paragraph-size;
    line-height: $line-height;
  }
}
h1, h2 {
  font-size: $paragraph-size;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
}
// h2 { font-size: 3rem; }
h3 {
  font-size: 2.2rem;
  @media screen and (min-width: $breakpoint-tablet-min){
    font-size: 2.8rem;
  }
}
h4 {
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 28px;
}
h5 {
  font-size: $paragraph-size;
  line-height: $line-height;
}
.teaser-project-client {
  font-size: 1.9rem;
  line-height: 1.25;
}
nav{
  font-size: 2.1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.logo {
  font-size: 3rem;
}
.carousel-status {
  font-size: $paragraph-size;
}
#footer p, .footer-nav .nav-item {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 400;
  text-align: center;
  @media screen and (min-width: $breakpoint-tablet-min){
    text-align: left;
  }
  @media screen and (min-width: $breakpoint-laptop-min){
    font-size: 1.4rem;
  }
}
.intro-text{
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 3.9rem;
  text-align: center;
  @media screen and (min-width: $breakpoint-laptop-min){
    font-size: 3.2rem;
  }
}
.single-project-headline{
  font-size: 3rem;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0.3px;
}
.profil-content-description{
  font-size: 2.1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 2.7rem;
}
