body {
  background-color: $background-color;
}
a {
  color: $font-color;
  text-decoration: none;
}
a:hover {
  color: $highligth;
}
.static-container{
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  border-bottom: solid 1px;
  @media screen and (min-width: $breakpoint-tablet-min){
    position: fixed;
    border-bottom: none;
    width: auto;
  }
}

.content{
  padding-top: 132px;
  @media screen and (min-width: $breakpoint-tablet-min){
    padding-top:0;
  }
}
// Type
h1{
  margin: 6rem 0;
  @media screen and (min-width: $breakpoint-laptop-min){
    margin: 8rem 0;
  }
}
h2 {
  margin: 6rem 0 3rem;
  @media screen and (min-width: $breakpoint-laptop-min){
    margin: 8rem 0 5rem;
  }
}
h3 {
  margin: 0 0 0 0;
}
h4 {
  margin: 0 0 0 0;
}
h5{
  margin: 0;
}
p{
  margin: 0;
}

// Images
.responsive-img{
  max-width: 100%;
  height: auto;
  display: block;
}
#homeloop{
  margin-top: 7rem;
  border: solid 4px #000;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: block;
  @media screen and (min-width: $breakpoint-smartlet-min){
    border-width:5px;
  }
  @media screen and (min-width: $breakpoint-tablet-min){
    border-width:6px;
  }
  @media screen and (min-width: $breakpoint-laptop-min){
    border-width:8px;
  }
}
#logo{
  width:80px;
  height:60px;
  @media screen and (min-width: $breakpoint-laptop-min){
    width:100px;
    height:80px;
  }
}

// Teaser, Featured Projects
.greeting{
  margin-bottom: 2rem;
}
.teaser-projects-list :last-child{
  margin-bottom: 0;
}
.teaser-project{
  margin: 0 $basic-margin 8rem $basic-margin;
  @media screen and (min-width: $breakpoint-tablet-min){
    margin: 0 0 8rem 0;
  }
}
.teaser-project-bgrect{
  background-color: $gray-230;
  z-index: 1;
}
.teaser-project-headline{
  margin-top: 1rem;
  z-index: 5;
}
.teaser-project-client{
  z-index: 5;
}
.teaser-project-browser{
  z-index: 10;
}
.teaser-project-text{
  padding: $basic-margin;
  @media screen and (min-width: $breakpoint-tablet-min){
    padding: 0;
  }
}
// Contacts
.contacts-container{
  border: solid 2px #000;
  padding: $basic-margin;
  box-sizing: border-box;
  margin: 8rem $basic-margin 16rem $basic-margin;
  @media screen and (min-width: $breakpoint-tablet-min){
    margin: 10rem 0 16rem 0;
  }
}
.contacts-container p{
  margin-bottom: 2rem;
}
.contacts-container :last-child{
  margin-bottom: 0;
}
// profil
.profil-content-service{
  margin: 5rem 1rem;
  @media screen and (min-width: $breakpoint-tablet-min){
    margin: 5rem 0;
  }
}

// Single Projects
.single-project-carousel{
  margin-top: 7rem;
}
.single-project-headline{
  margin-bottom: 4rem;
}
.single-project-infos p {
  margin-bottom: $line-height;
}
.single-project-infos-left :last-child,
.single-project-infos-right :last-child{
  margin-bottom: 0;
}
