/*--------------------------------------------
FLICKITY PRESETS
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: hsla(0, 0%, 100%, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flickity-prev-next-button:hover { background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

/*
Carousel Options
*/
.carousel{
  padding: 0 $basic-margin;
  @media screen and (min-width: $breakpoint-tablet-min){
    padding: 0;
  }
}

.carousel-item {
  width: 100%;
  margin-right: 10px;
}
.carousel-item img{
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.carousel-item-image {
  transition: opacity 0.4s;
  opacity: 0;
}

.carousel-item-image.flickity-lazyloaded,
.carousel-item-image.flickity-lazyerror {
  opacity: 1;
}
.button-row, .scroll-row{
  // position: absolute;
  // bottom: 1rem;
  // right: 1rem;
  text-align: center;
  margin-top: 1rem;
}
.button-row button{
  background:rgba(255, 255, 255, 0);
  width: 63px;
  height: 31px;
  font-size: 0px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  z-index: 20;
}
.button-row button:focus{
  outline-width: 0;
}
.button-row button:hover{
    fill: $highligth;
}
.carousel-status{
  display: inline-block;
  margin: 0 1rem;
}
.scroll-arrow{
  display: inline-block;
  height:20px;
  width:12px;
}
