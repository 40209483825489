/*
---------------------
Grid-Layout
---------------------
*/
#wrapper{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: $breakpoint-maximum;
  margin: 0 auto;
}
.col-12{
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
}
.col-8{
  flex-basis: 66.66666%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 66.66666%;
}
.col-6{
  flex-basis: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 50%;
}
.col-4{
  flex-basis: 33.33333%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 33.33333%;
}

/*
--------------------
content for #wrapper
--------------------
*/
#header {
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  @media screen and (min-width: $breakpoint-tablet-min){
    flex-basis: 20%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 20%;
  }
}

#main{
    // grid-template-columns: repeat(12, [main-col] 1fr);
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (min-width: $breakpoint-tablet-min){
    flex-basis: 70%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 70%;
  }
}
// #main-wrapper{
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
// }

// content für #main: PROJECTS
.teaser-projects{
  // grid-column: main-col 1 / -1;
  // flex-basis: 100%;
  // flex-grow: 0;
  // flex-shrink: 0;
  // max-width: 100%;
}
// content für #main: .sayhello
.sayhello{
  // grid-column: main-col 5 / -5;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  @media screen and (min-width: $breakpoint-smartlet-min){
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: $breakpoint-tablet-min){
    flex-basis: 50%;
    max-width: 50%;
  }
  @media screen and (min-width: $breakpoint-laptop-min){
    flex-basis: 40%;
    max-width: 40%;
  }
}
// content für #main: HOMEPAGE
.intro{
  flex-basis: 83.333333%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 83.333333%;
  // grid-column: main-col 2 / -2;
}
.greeting{
  // grid-column: main-col 3 / -3;
  flex-basis: 66.66666%;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 66.66666%;
  @media screen and (min-width: $breakpoint-laptop-min){
    flex-basis: 50%;
    max-width: 50%;
  }
}
.featured-projects{
  // grid-column: main-col 1 / -1;
  position: relative;
}
// Grid for teaser
.teaser-project-content{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0;
  @media screen and (min-width: $breakpoint-smartlet-min){
    grid-template-columns: 1fr 8fr 1fr 4fr 10fr;
    grid-template-rows: $browser-text-versatz 1fr $browser-text-versatz;
  }
}
.teaser-project-bgrect{
  grid-column: 1;
  grid-row: 2;
  @media screen and (min-width: $breakpoint-smartlet-min){
    grid-column: 1 / -2;
    grid-row: 2 / -1;
  }
}
.teaser-project-text{
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (min-width: $breakpoint-smartlet-min){
    grid-column: 2 / 3;
    grid-row: 2 / -2;
  }
}
// .teaser-project-client{
// }
// .teaser-project-headline{
// }
.teaser-project-browser{
  grid-column: 1;
  grid-row: 1;
  @media screen and (min-width: $breakpoint-smartlet-min){
    grid-column: 4 / -1;
    grid-row: 1 / -2;
  }
}
// content für #main: Profil
.profil-content{
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.profil-content-headline{
  grid-column: 1 / -1;
  // grid-row: 1;
}
.profil-content-description{
  grid-column: 3 / -3;
}
.profil-content-service{
  grid-column: 1 / -1;
}
.profil-content-namensgebungImage{
  grid-column: 1 / 7;
}
.profil-content-name{
  grid-column: 8 / 12;
}
// content für #main: SINGLEWEBPROJECTS
.single-project-carousel{
  // grid-column: main-col 1 / -1;
}
.single-project-headline{
  // grid-column: main-col 3 / -3;
}
.single-project-infos{
  display: flex;
  justify-content: center;
  // grid-column: main-col 3 / -3;
}
.single-project-infos-left{
  padding-right: $basic-margin;
}
.single-project-infos-right{
  padding-left: $basic-margin;
}
