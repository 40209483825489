.browser-container{
  box-shadow: 0px 15px 40px RGBA(0,0,0,0.15);
}
.teaser-project-browser .browser-container{
  box-shadow: 0px 7px 20px RGBA(0,0,0,0.15);
  @media screen and (min-width: $breakpoint-smartlet-min){
    box-shadow: -8px 8px 25px RGBA(0,0,0,0.15);
  }
}
.browser-header{
  background-color: #DFDEDF;
  background: linear-gradient(#ECEBEC, #D6D5D6); /* Standard syntax */
  border-bottom: solid 1px #B6B5B6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-sizing: border-box;
  height: 32px;
  width: 100%;
  display: flex;
  padding: 0 1.4rem;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: $breakpoint-laptop-min){
    height: 38px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.browser-header::after{
  content: "fake";
  width: $browser-buttons-width;
  background: #123456;
  visibility: hidden;
}
.browser-control-knobs{;
  font-size: 0px;
}
.browser-control-knob{
  border: solid 1px;
  border-radius: 50%;
  height: $browser-button-diameter;
  width: $browser-button-diameter;
  display: inline-block;
}
.red-knob{
  background-color: #FA6058;
  border-color: #E14540;
}
.yellow-knob{
  background-color: #FCBD2E;
  border-color: #E0A027;
  margin: 0 8px;
}
.green-knob{
  background-color: #30CA41;
  border-color: #2EAD31;
}
.browser-search-bar{
  display: flex;
  height: 18px;
  flex-grow: 1;
  max-width: 500px;
  border-radius: 2px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  margin: 0 1.4rem;
  @media screen and (min-width: $breakpoint-laptop-min){
    height: 22px;
    border-radius: 4px;
  }
}
.browser-url{
  display: inline-block;
  font-size: 11px;
  color: $gray-200;
}
.browser-content-image{
  height: 100%;
  background-color: $gray-120;
  background-image: url("./img/preloader.svg");
  background-position: center;
  background-repeat: no-repeat;

}
.home .browser-content-image {
  height: 0;
  padding-top: 510px / 690px * 100%;
  position: relative;
}
.webproject-browser{
  padding: 40px 38px;
}

.webproject .single-project .browser-content-image,
.webproject-animation .single-project .browser-content-image{
  width: 100%;
  padding-top: 66%;
  overflow-y: auto;
  position: relative;
}
.webproject-animation .single-project .browser-content-image{
  overflow: hidden;
}
.webproject .single-project img,
.home .browser-content-image img{
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
.video-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container video{
  max-width: 100%;
  height: auto;
}
.webproject .scroll-batch{
  position: absolute;
  bottom: 0;
  height: 160px;
  left: 50%;
  margin-left: -80px;
  margin-bottom: -80px;
  opacity:0;
  z-index: 100;
  width:160px;
}
.scroll-batch .scroll-batch-text{
  font-family:'URW Grotesk';
  font-size: 24px;
  font-weight: 700;
  fill: $gray-60;
}
.scroll-batch .scroll-batch-form-fill{
  fill: $gray-60;
}
.scroll-batch .scroll-batch-form-stroke{
  stroke: $highligth;
}
