.header-nav{
  margin:$basic-margin;
}
.logo-container{
  margin: 4rem $basic-margin $basic-margin;
  text-align: center;
  @media screen and (min-width: $breakpoint-tablet-min){
    text-align: left;
  }
}
.stickynav{
  position: fixed;
  top: -95px;
  @media screen and (min-width: $breakpoint-tablet-min){
    top: 0;
  }
}
.navMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media screen and (min-width: $breakpoint-tablet-min){
    flex-direction: column;
    justify-content:flex-start;
  }
  padding:0;
}

.footer-nav {
  margin: 0;
  @media screen and (min-width: $breakpoint-tablet-min){
    margin-left: $basic-margin;
  }
}
.footer-nav .navMenu{
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#footer p{
  margin-left: 0;
  @media screen and (min-width: $breakpoint-tablet-min){
    margin-left: $basic-margin;
  }
}
#footer{
  max-width: $breakpoint-maximum;
  margin: 0 auto $basic-margin;
}
