// Layout
$breakpoint-maximum: 1680px;
$breakpoint-laptop-min: 1080px;
$breakpoint-tablet-min: 760px;
$breakpoint-smartlet-min: 500px;

// type
$html-size: 10px;
$html-size-sm: 8px;
$paragraph-size: 1.7rem;
$paragraph-size-sm: 1.8rem;
$line-height: 2.25rem;
$line-height-sm: 2.25rem;

// Colors
$gray-200: #c8c8c8;
$gray-230: #e6e6e6;
$gray-120: #787878;
$gray-60: #3c3c3c;
$highligth: #14d4a4; // #e16aff?
$background-color: #ffffff;
$link-color: #e61919;
$font-color: #000000;

//Margins and Paddings
$basic-margin: 2rem;

//browser
$browser-button-diameter: 1.0rem;
$browser-button-margin: 0.8rem;
$browser-buttons-width: $browser-button-diameter * 3 + $browser-button-margin * 2;
$browser-text-versatz: 6rem;
